import React, { Component } from 'react';
// import Paper from '@material-ui/core/Paper';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';

const rsvpFormLink = "https://docs.google.com/forms/d/e/1FAIpQLSd2KCJf5x5xh05AXRgzh3rq17SI-oNJT0W0xD7jsHJAKidy4Q/viewform?embedded=true";

class Rsvp extends Component {
  constructor(props) {
    super(props)
    this.render = this.render.bind(this);
  }

  render() {
    return (
      <div className="Rsvp-content">
        <iframe
          title="rsvp-form"
          src={rsvpFormLink} 
          width="100%" height="900px"
          frameBorder="0" marginHeight="0" marginWidth="0">
          Loading...
        </iframe>
      </div>
    );
  }
}

export default Rsvp;
