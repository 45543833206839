import React, { Component } from 'react';
import {BrowserRouter, Routes, Route, Link} from 'react-router-dom';
import './App.css';
import './Link.css';
import Rsvp from './Rsvp';
import Paper from '@material-ui/core/Paper';

const mapsEmbedLink = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d158861.72423805285!2d-9.000387935296244!3d52.81243875436736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x485b6b7174058fef%3A0x53509393579a64b8!2sDromoland%20Castle%20Hotel!5e0!3m2!1sen!2sch!4v1661895853126!5m2!1sen!2sch";

class App extends Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
  }

  renderHeader() {
    return (
      <div>
        <p><b>Cian and Ciara&apos;s Wedding</b></p>
        <div className="Header-links-box">
          <Link className="link link--metis" to="">Location</Link>&nbsp;&nbsp;&nbsp;
          <Link className="link link--metis" to="accommodation">Accommodation</Link>&nbsp;&nbsp;&nbsp;
          <Link className="link link--metis" to="rsvp">R.S.V.P.</Link>
        </div>
        <hr/>
      </div>
    );
  }

  renderHome() {
    return (
      <div>
        <p>We are getting married.</p>
      </div>
    );
  }

  telHref(t) {
    return (<a href={'tel:'+t}>{t}</a>);
  }

  renderAccom() {
    let otherHotels = [
      {name: 'Old Ground Hotel', url: 'www.oldgroundhotelennis.com'},
      {name: 'Temple Gate Hotel', url: 'www.templegatehotel.com'},
      {name: 'Bunratty Castle Hotel', url: 'www.bunrattycastlehotel.com'},
      {name: 'Bunratty Manor Hotel', url: 'www.bunrattymanor.ie'},
      {name: 'Hunters Lodge', url: 'www.hunterslodge.ie'},
      {name: 'Carrygerry Country House', url: 'www.carrygerryhouse.com'},
      {name: 'Radisson Blu Limerick', url: 'www.radlimerick.com'},
    ];
    return (
      <div className="Main-content">
        We&apos;ve organised discount room rates at the following hotels, quote our name when calling:
        <h2>Dromoland Castle</h2>
        <p>
          <b>Standard room:</b> €415<br/>
          <b>Deluxe room:</b>   €455<br/>
          <b>Contact number:</b> {this.telHref("+353 61 368 144")}<br/>
        </p>
        <p>
          Breakfast is included. Rates valid until Sep 28th. Free cancellation until 21 days before.
        </p>
        <h2>The Inn at Dromoland</h2>
        <p>
          The Inn is located a 5 minute drive from the castle. There will be shuttles offered on the day to & from the Inn at peak hours.
        </p>
        <p>
          <b>Superior room:</b> €140<br/>
          <b>Premier room:</b> €160<br/>
          <b>Contact number:</b> {this.telHref("+353 61 368 161")}<br/>
        </p>
        <p>
        Breakfast is included. Rates valid until Oct 28th. Free cancellation until 48 hours before.
        </p>
        <h2>Other Options</h2>
        <p>All of the following hotels are within 10km of Dromoland Castle.</p>
        <ul>
          {otherHotels.map(function(h){
            return (<li><a href={'http://'+h.url}>{h.name}</a></li>);
          })}
        </ul>
      </div>
    );
  }

  renderLocation() {
    return (
      <div className="Main-content">
        <p>Dromoland Castle, Co. Clare</p>
        <p>13:30, 29th December 2022</p>
        <iframe
          title="dromolandLocation"
          src={mapsEmbedLink}
          width="100%"
          style={{border: 0, aspectRatio: '1/1'}}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade">
        </iframe>
      </div>
    );
  }
  
  render() {
    return (
      <BrowserRouter>
      <div className="App">
        <Paper className="Paper-box" elevation={6}>
          <div className="Main-header">
            {this.renderHeader()}
          </div>
          <div>
            <Routes>
              <Route path="/" element={this.renderLocation()} />
              <Route path="/accommodation" element={this.renderAccom()} />
              <Route path="/rsvp" element={<Rsvp/>} />
            </Routes>
          </div>
        </Paper>
      </div>
      </BrowserRouter>
    );
  }
}

export default App;
